<template>
  <div class="home-container">
    <!-- 用户信息盒子 -->
    <div class="container-top">
      <div class="container-left" v-if="!infoFlag"></div>
      <div class="container-left" v-if="infoFlag">
        <div class="container-left-top">
          <div class="user-img">
            <img
              @dragstart.prevent
              :src="userInfo.imgUrl"
              width="100%"
              height="100%"
              alt=""
              style="border-radius: 50%"
            />
            <span class="type-style">{{ userInfo.consumerTypes }}</span>
          </div>
          <div class="user-info">
            <el-tooltip
              effect="light"
              :content="userInfo.consumerName"
              placement="bottom-start"
            >
              <div class="user-txt text-ellipsis">
                {{ userInfo.consumerName }}
              </div>
            </el-tooltip>
            <div class="user-member">
              <div class="user-icon" v-if="userInfo.isMember != 0">
                <img
                  @dragstart.prevent
                  src="../../../assets/img/user/vip-icon.png"
                  alt=""
                  width="100%"
                  height="100%"
                />
                <span class="vip-style">{{ userInfo.memberName }}</span>
              </div>
              <div class="user-normal-icon" v-if="userInfo.isMember == 0">
                <span class="normal-style">普通用户</span>
              </div>
            </div>
          </div>
          <div class="info-read">
            <div class="unread-num" @click="unreadMsg">
              {{
                userInfo.newsInteractionCount +
                  userInfo.newsOrderCount +
                  userInfo.newsSysCount || 0
              }}
            </div>
            <div class="unread-txt" @click="unreadMsg">
              <span class="iconfont icon-xiaoxi"></span>
              <span class="unread-msg">未读消息</span>
            </div>
          </div>
          <div class="my-collect">
            <div class="collect-num" @click="goCollect">
              {{ userInfo.collectionCount }}
            </div>
            <div class="collect-txt" @click="goCollect">
              <span class="iconfont icon-shoucang1"></span>
              <span class="collect-msg">我的收藏</span>
            </div>
          </div>
        </div>
        <div class="container-left-bottom">
          <div class="info-title">
            <span class="info-txt">
              信息完度
              <span class="info-num">({{ userInfo.integrity * 10 }}%)</span>
            </span>
            <span class="info-num go-perfect" @click="goPerfect">{{
              userInfo.integrity == 10 ? "去修改>>" : "去完善>>"
            }}</span>
          </div>
          <div class="info-process">
            <el-progress
              v-if="userInfo.integrity"
              :percentage="userInfo.integrity * 10"
              :stroke-width="10"
              :show-text="false"
              color="#1181fa"
            ></el-progress>
          </div>
        </div>
      </div>
      <div class="container-right" v-if="!infoFlag"></div>
      <div class="container-right" v-if="infoFlag">
        <span class="member-title">会员中心</span>
        <!-- 会员显示 -->
        <div class="vip-box" v-if="userInfo.isMember != 0&&userInfo.memberId != 5&&userInfo.memberId != 6">
          <span class="vip-date"
            >{{ userInfo.memberName }}会员有效期至：{{
              userInfo.expireDate
            }}</span
          >
          <!-- <span class="vip-download"
            >研报剩余下载：{{
              userInfo.downloadTimes == -1 ? "无限" : userInfo.downloadTimes
            }}次</span>  -->
					<span class="vip-download">历史项目剩余下载：{{ historyDataDownloadTimes == -1 || historyDataDownloadTimes ==-2 ? "无限" : historyDataDownloadTimes }}次</span>
				</div>
				<div class="function-box" v-if="userInfo.isMember == 1 && !userInfo.pid">
					<span class="renew" @click="renewVip(userInfo.memberName)" v-show="memberId != 5 && memberId != 6">立即续费</span>
					<span class="record" @click="buyRecord">购买记录</span>
				</div>
				<!-- 非会员显示 -->
				<div class="novip-box" v-if="userInfo.isMember == 0">
					<div class="novip-txt">尊敬的用户，开通会员享受更多的会员权益~</div>
					<div class="vip-btn" @click="goVip">开通会员</div>
					<!-- <div class="novip-record" @click="buyRecord">购买记录</div> -->
				</div>
			</div>
		</div>
		<!-- 消息内容盒子 -->
		<div class="container-bottom">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane name="interactionMsg">
					<span slot="label" class="tab-box">
						互动消息
						<div class="dot" v-if="userInfo.newsInteractionCount != 0"></div>
					</span>
				</el-tab-pane>
				<el-tab-pane name="orderMsg">
					<span slot="label" class="tab-box">
						订单消息
						<div class="dot" v-if="userInfo.newsOrderCount != 0"></div>
					</span>
				</el-tab-pane>
				<el-tab-pane name="systemMsg">
					<span slot="label" class="tab-box">
						系统消息
						<div class="dot" v-if="userInfo.newsSysCount != 0"></div>
					</span>
				</el-tab-pane>
			</el-tabs>
			<!-- 互动消息 -->
			<div class="msg-box-left" v-show="activeName == 'interactionMsg'">
				<div class="msg-content">
					<div v-if="total1 == 0" style="width: 100%; height: 100%; display: flex; justify-content: center">
						<empty name="暂无数据"></empty>
					</div>
					<div class="msg-item-box" v-for="(item, index) in interactionMsgList" :key="index">
						<img src="../../../assets/img/user/yonghu.png" alt="" width="100%" height="100%" class="img-style" />
						<el-tooltip effect="light" :content="item.newsInfo" placement="bottom-start">
							<span class="item-content">{{ item.newsInfo }}</span>
						</el-tooltip>
						<span class="time-style">{{ item.createDate }}</span>
						<div :class="item.isRead == 60541001 ? 'btn-style replyed-btn' : 'btn-style no-replyed-btn'" @click="goReply(item)">
							{{ item.isRead == 60541001 ? "已回复" : "回复" }}
						</div>
					</div>
				</div>
				<div class="pagination-box">
					<pagination ref="pagination1" :total="total1" @change="paginChange1" v-if="total1 != 0"></pagination>
				</div>
			</div>
			<!-- 订单消息 -->
			<div class="msg-box-medium" v-show="activeName == 'orderMsg'">
				<div class="msg-content">
					<div v-if="total2 == 0" style="width: 100%; height: 100%; display: flex; justify-content: center">
						<empty name="暂无数据"></empty>
					</div>
					<div class="msg-item-box" v-for="(item, index) in orderMsgList" :key="index">
						<img src="../../../assets/img/user/ordermsg.png" alt="" width="100%" height="100%" class="img-style" />
						<div class="order-detail">
							<el-tooltip effect="light" :content="item.newsInfo" placement="bottom-start">
								<div class="order-title">
									{{ item.newsInfo }}
								</div>
							</el-tooltip>
							<span class="payment" v-show="item.paymentDate">支付时间：{{ item.paymentDate }}</span>
							<span class="payment-amount">
								支付金额：
								<span class="paymoney">{{ item.orderAmount }}</span>
								元
							</span>
						</div>
						<span class="time-style">{{ item.createDate }}</span>
						<div :class="item.isRead == 60541001 ? 'btn-style replyed-btn' : 'btn-style no-replyed-btn'" @click="goRead(item)">
							{{ item.isRead == 60541001 ? "已读" : "未读" }}
						</div>
					</div>
				</div>
				<div class="pagination-box">
					<pagination ref="pagination2" :total="total2" @change="paginChange2" v-if="total2 != 0"></pagination>
				</div>
			</div>
			<!-- 系统消息 -->
			<div class="msg-box-right" v-show="activeName == 'systemMsg'">
				<div class="msg-content">
					<div v-if="total3 == 0" style="width: 100%; height: 100%; display: flex; justify-content: center">
						<empty name="暂无数据"></empty>
					</div>
					<div class="msg-item-box" v-for="(item, index) in systemMsgList" :key="index">
						<img src="../../../assets/img/user/notice.png" alt="" width="100%" height="100%" class="img-style" />
						<div class="order-detail">
							<el-tooltip effect="light" :content="item.newsTitle" placement="bottom-start">
								<div :class="item.linkUrl ? 'link-style order-title' : 'order-title'" @click="handleClickToInfo(item)">
									{{ item.newsTitle }}
								</div>
							</el-tooltip>
							<el-tooltip effect="light" :content="item.newsInfo" placement="bottom-start">
								<span class="activity">{{ item.newsInfo }}</span>
							</el-tooltip>
						</div>
						<span class="time-style">{{ item.createDate }}</span>
						<div :class="item.isRead == 60541001 ? 'btn-style replyed-btn' : 'btn-style no-replyed-btn'" @click="goRead(item)">
							{{ item.isRead == 60541001 ? "已读" : "未读" }}
						</div>
					</div>
				</div>
				<div class="pagination-box">
					<pagination ref="pagination3" :total="total3" @change="paginChange3" v-if="total3 != 0"></pagination>
				</div>
			</div>
		</div>
		<!-- 互动弹窗dialog -->
		<div class="wai-hudong">
			<Modal :visible.sync="visible" @reset="resetFields" class="dialog-style">
				<div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">
					{{ title }}
				</div>
				<el-form :model="dataPO" :rules="rules" ref="dataPO" label-width="100px" class="demo-ruleForm" :disabled="disabled">
					<el-row>
						<el-col :span="24">
							<el-form-item label="留言内容" prop="content" class="span-item title-item">
								<span>{{ dataPO.newsInfo }}</span>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="回复内容" prop="replyInfo" class="desc-item title-item">
								<el-input type="textarea" v-model="dataPO.replyInfo" :rows="6" resize="none" placeholder="限200字以内"></el-input>
							</el-form-item>
						</el-col>
						<el-col>
							<el-form-item class="title-item btn-item">
								<el-button type="primary" @click="submitForm('dataPO')" v-if="dataPO.isRead == '60541002'">回 复</el-button>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</Modal>
		</div>
		<!-- 订单消息弹窗dialog -->
		<div class="wai-order">
			<Modal :visible.sync="orderVisible" @reset="resetOrderFields" class="dialog-style">
				<div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">
					查看消息
				</div>
				<el-form :model="orderDataPO" ref="orderDataPO" label-width="100px" class="demo-ruleForm" :disabled="disabled">
					<el-row>
						<el-col :span="24">
							<el-form-item label="消息内容" prop="newsInfo" class="span-item title-item">
								<span>{{ orderDataPO.newsInfo }}</span>
							</el-form-item>
						</el-col>
						<el-col :span="24" v-show="orderDataPO.paymentDate">
							<el-form-item label="支付时间" prop="paymentDate" class="span-item title-item">
								<span>{{ orderDataPO.paymentDate }}</span>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="支付金额" prop="orderAmount" class="span-item title-item">
								<span>{{ orderDataPO.orderAmount }}元</span>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</Modal>
		</div>
		<!-- 系统消息弹窗dialog -->
		<div class="wai-system">
			<Modal :visible.sync="sysVisible" @reset="resetSysFields" class="dialog-style">
				<div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">
					查看消息
				</div>
				<el-form :model="sysDataPO" ref="sysDataPO" label-width="100px" class="demo-ruleForm" :disabled="disabled">
					<el-row>
						<el-col :span="24">
							<el-form-item label="标题" prop="newsTitle" class="span-item title-item">
								<span>{{ sysDataPO.newsTitle }}</span>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="消息内容" prop="newsInfo" class="span-item title-item">
								<span>{{ sysDataPO.newsInfo }}</span>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="时间" prop="createDate" class="span-item title-item">
								<span>{{ sysDataPO.createDate }}</span>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</Modal>
		</div>
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Modal from "@/components/Modal";
import Empty from "@/components/Empty";
import axios from "axios";
export default {
	components: {
		Pagination,
		Modal,
		Empty,
	},
	data() {
		return {
			userInfo: {
				newsInteractionCount: 0,
				newsOrderCount: 0,
				newsSysCount: 0,
				collectionCount: 0,
				integrity: 0,
			},
			infoFlag: false,
			// 用户信息
			activeName: "interactionMsg",
			title: "留言回复",
			total1: 0,
			total2: 0,
			total3: 0,
			interactionDot: false,
			orderDot: false,
			systemDot: false,
			// 互动消息列表
			interactionMsgList: [],
			// 订单消息列表
			orderMsgList: [],
			// 系统消息列表
			systemMsgList: [],
			params1: {
				pageNum: 1,
				pageSize: 10,
			},
			params2: {
				pageNum: 1,
				pageSize: 10,
			},
			params3: {
				pageNum: 1,
				pageSize: 10,
			},
			visible: false,
			orderVisible: false,
			sysVisible: false,
			disabled: false,
			dataPO: {
				newsInfo: "",
				replyInfo: "",
			},
			orderDataPO: {},
			sysDataPO: {},
			unreadArr: [],
			flag: "",
			rules: {
				replyInfo: [
					{required: true, message: "请输入您的回复内容", trigger: "blur"},
					{
						max: 200,
						message: "限200字以内",
						trigger: "blur",
					},
				],
			},
			memberId: "",
      historyDataDownloadTimes:0,//历史项目数据下载次数
		};
	},
	computed: {},
	created() {},
	mounted() {
		this.getInfo();
		this.getUnreadArr();
		this.memberId = this.$session.getUsers().memberId;
    this.getMemberPermission()
	},
	methods: {
		// 获取历史项目数据下载次数
		getMemberPermission() {
			let params = {
				moduleCode: 1007,
				childModuleCode: 10071001,
				type: 1,
			};
			this.$api.industry.getMemberPermission(params).then(res => {
				this.historyDataDownloadTimes = res.data;
			});
		},
		// 获取用户信息
		getInfo() {
			this.$api.personalCenter
				.getInfo()
				.then(res => {
					let user = res.data;
					user.integrity = +user.integrity;
					if (!user.imgUrl) {
						user.imgUrl = require("../../../assets/img/user/user-img.png");
					}
					this.infoFlag = true;
					this.userInfo = user;
					this.$session.setUsers(user);
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
					this.infoFlag = false;
				});
		},
		// 获取互动消息列表
		getNewsInteraction() {
			this.$api.personalCenter.getNewsInteraction(this.params1).then(res => {
				this.interactionMsgList = res.rows;
				this.total1 = res.total;
				// this.dotShow();
			});
		},
		// 获取订单消息列表
		getNewsOrder() {
			this.$api.personalCenter.getNewsOrder(this.params2).then(res => {
				this.orderMsgList = res.rows;
				this.total2 = res.total;
				// this.dotShow();
			});
		},
		// 获取系统消息列表
		getNewsSystem() {
			this.$api.personalCenter.getNewsSystem(this.params3).then(res => {
				this.systemMsgList = res.rows;
				this.total3 = res.total;
				// this.dotShow();
			});
		},
		// 获取未读消息列表
		getUnreadArr() {
			this.unreadArr = [];
			axios.all([this.$api.personalCenter.getNewsInteraction(this.params1), this.$api.personalCenter.getNewsOrder(this.params2), this.$api.personalCenter.getNewsSystem(this.params3)]).then(
				axios.spread((interaction, order, system) => {
					let interactionMsgList = interaction.rows;
					let orderMsgList = order.rows;
					let systemMsgList = system.rows;
					this.interactionMsgList = interactionMsgList;
					this.orderMsgList = orderMsgList;
					this.systemMsgList = systemMsgList;
					this.total1 = interaction.total;
					interactionMsgList.forEach(item => {
						if (item.isRead == "60541002") {
							item.flag = "1";
							this.unreadArr.push(item);
						}
					});
					orderMsgList.forEach(item => {
						if (item.isRead == "60541002") {
							item.flag = "2";
							this.unreadArr.push(item);
						}
					});
					systemMsgList.forEach(item => {
						if (item.isRead == "60541002") {
							item.flag = "3";
							this.unreadArr.push(item);
						}
					});
					this.$nextTick(() => {
						let arr = [];
						this.unreadArr.forEach(item => {
							arr.push(Date.parse(item.createDate));
						});
						let newTime = arr.sort().reverse()[0];
						this.$nextTick(() => {
							this.unreadArr.forEach(item => {
								if (newTime == Date.parse(item.createDate)) {
									this.flag = item.flag;
									this.$forceUpdate();
								}
							});
						});
					});
				})
			);
		},
		// 去完善
		goPerfect() {
			this.$router.push("/user/my-center/modify-data");
		},
		// 未读消息
		unreadMsg() {
			// 下面的消息盒子展示到最新一条
			if (this.flag == "1") {
				this.activeName = "interactionMsg";
				this.getNewsInteraction();
			} else if (this.flag == "2") {
				this.activeName = "orderMsg";
				this.getNewsOrder();
			} else if (this.flag == "3") {
				this.activeName = "systemMsg";
				this.getNewsSystem();
			} else {
				return;
			}
		},
		// 跳转我的收藏
		goCollect() {
			this.$router.push("/user/my-collect");
		},
		// 开通会员
		goVip() {
			let url = this.$router.resolve({
				path: `/member`,
			});
			window.open(url.href, "_blank");
		},
		// 立即续费
		renewVip(val) {
			console.log(val);
			let type = "";
			if (val == "VIP") {
				type = "1";
			} else if (val == "SVIP") {
				type = "2";
			}
			this.$router.push("/member");
		},
		// 购买记录
		buyRecord() {
			this.$router.push("/user/order-record");
		},
		// tab切换
		handleClick(tab) {
			if (tab.name == "interactionMsg") {
				this.getNewsInteraction();
			} else if (tab.name == "orderMsg") {
				this.getNewsOrder();
			} else if (tab.name == "systemMsg") {
				this.getNewsSystem();
			}
		},
		// 切换分页
		paginChange1(page, pageSize) {
			this.params1.pageNum = page;
			this.params1.pageSize = pageSize;
			if (this.activeName == "interactionMsg") {
				this.getNewsInteraction();
			} else if (this.activeName == "orderMsg") {
				this.getNewsOrder();
			} else if (this.activeName == "systemMsg") {
				this.getNewsSystem();
			}
		},
		paginChange2(page, pageSize) {
			this.params2.pageNum = page;
			this.params2.pageSize = pageSize;
			if (this.activeName == "interactionMsg") {
				this.getNewsInteraction();
			} else if (this.activeName == "orderMsg") {
				this.getNewsOrder();
			} else if (this.activeName == "systemMsg") {
				this.getNewsSystem();
			}
		},
		paginChange3(page, pageSize) {
			this.params3.pageNum = page;
			this.params3.pageSize = pageSize;
			if (this.activeName == "interactionMsg") {
				this.getNewsInteraction();
			} else if (this.activeName == "orderMsg") {
				this.getNewsOrder();
			} else if (this.activeName == "systemMsg") {
				this.getNewsSystem();
			}
		},
		// 判断dot红点是否显示
		dotShow() {
			let arr1 = [];
			let arr2 = [];
			let arr3 = [];
			if (this.interactionMsgList) {
				this.interactionMsgList.forEach(item => {
					arr1.push(item.isRead);
					if (arr1.indexOf("60541002") == -1) {
						this.interactionDot = false;
					} else {
						this.interactionDot = true;
					}
				});
			}
			if (this.orderMsgList) {
				this.orderMsgList.forEach(item => {
					arr2.push(item.isRead);
					if (arr2.indexOf("60541002") == -1) {
						this.orderDot = false;
					} else {
						this.orderDot = true;
					}
				});
			}
			if (this.systemMsgList) {
				this.systemMsgList.forEach(item => {
					arr3.push(item.isRead);
					if (arr3.indexOf("60541002") == -1) {
						this.systemDot = false;
					} else {
						this.systemDot = true;
					}
				});
			}
		},
		// 回复消息
		goReply(val) {
			this.visible = true;
			this.dataPO = JSON.parse(JSON.stringify(val));
			if (val.isRead == "60541001") {
				this.disabled = true;
				this.title = "查看留言";
			} else {
				this.disabled = false;
				this.title = "留言回复";
			}
		},
		// 查看按钮
		goRead(val) {
			if (val.orderId) {
				this.orderVisible = true;
				this.orderDataPO = JSON.parse(JSON.stringify(val));
				if (val.isRead == "60541001") {
					return;
				} else {
					this.$api.personalCenter
						.newsOrderRead({
							newsOrderRelId: val.newsId,
						})
						.then(res => {
							if (res.code == 200) {
								this.getUnreadArr();
								// this.getNewsOrder();
								this.getInfo();
							}
						});
				}
			}
			if (val.newsSystemId) {
				if (val.newsTitle == "订阅消息") {
					if (val.isRead == "60541001") {
						this.$router.push("/user/my-collect/subscription");
					} else {
						this.$api.personalCenter
							.newsSystemRead({
								newsSystemRelId: val.id,
							})
							.then(res => {
								if (res.code == 200) {
									this.$router.push("/user/my-collect/subscription");
								}
							});
					}
				} else {
					this.sysVisible = true;
					this.sysDataPO = JSON.parse(JSON.stringify(val));
					if (val.isRead == "60541001") {
						return;
					} else {
						this.$api.personalCenter
							.newsSystemRead({
								newsSystemRelId: val.id,
							})
							.then(res => {
								if (res.code == 200) {
									this.getUnreadArr();
									// this.getNewsSystem();
									this.getInfo();
								}
							});
					}
				}
			}
		},
		// 系统消息点击标题跳转看消息
		handleClickToInfo(val) {
			if (val.linkUrl) {
				this.$api.personalCenter
					.newsSystemRead({
						newsSystemRelId: val.id,
					})
					.then(res => {
						if (res.code == 200) {
							this.getUnreadArr();
							// this.getNewsSystem();
							this.getInfo();
						}
					});
				window.open(val.linkUrl, "_blank");
			} else {
				return;
			}
		},
		// 关闭弹窗时重置表单
		resetFields() {
			this.$refs.dataPO.resetFields();
		},
		resetOrderFields() {
			this.$refs.orderDataPO.resetFields();
		},
		resetSysFields() {
			this.$refs.sysDataPO.resetFields();
		},
		// 表单提交事件
		submitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$api.personalCenter
						.newsInteractionReply({
							newsInteractionId: this.dataPO.newsId,
							replyInfo: this.dataPO.replyInfo,
						})
						.then(res => {
							if (res.code == 200) {
								this.$message.success("提交成功");
								this.visible = false;
								this.dataPO = {};
								this.getUnreadArr();
								this.getInfo();
							}
						});
				} else {
					this.$message.error("提交失败");
					return false;
				}
			});
		},
	},
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
